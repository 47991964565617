.ace_gutter {
  background: #ebebeb;
  color: #333
}

.ace_print-margin {
  width: 1px;
  background: #e8e8e8
}

 {
  background-color: #F9F9F9;
  color: #080808
}

.ace_cursor {
  color: #000000
}

.ace_marker-layer.ace_selection {
  background: rgba(39, 95, 255, 0.30)
}

.ace_comment.ace_line.ace_double-slash {
    color: #999999;
    font-style: italic;
}

.ace-dawn.ace_multiselect.ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #F9F9F9;
}

.ace_marker-layer.ace_step {
  background: rgb(255, 255, 0)
}

.ace_marker-layer.ace_bracket {
  margin: -1px 0 0 -1px;
  border: 1px solid rgba(75, 75, 126, 0.50)
}

.ace_marker-layer.ace_active-line {
  background: rgba(36, 99, 180, 0.12)
}

.ace_gutter-active-line {
  background-color : #dcdcdc
}

.ace_marker-layer.ace_selected-word {
  border: 1px solid rgba(39, 95, 255, 0.30)
}

.ace_invisible {
  color: rgba(75, 75, 126, 0.50)
}

.ace_selection {
  background: rgba(90, 100, 126, 0.3)
}

.ace_multiselect.ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #323232;
}
